import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "gatsby-plugin-react-i18next";
import Typography from "@mui/material/Typography";
import Box, {BoxProps} from "@mui/material/Box";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import SectionTitle from "components/atoms/SectionTitle";

const SectionContent = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      "& h2": {
        px: {sx: 2, md: 4, lg: 6},
        pt: {sx: 2, md: 4, lg: 6}
      },
      "& p": {
        px: {sx: 2, md: 4, lg: 6}
      },
      ...props.sx
    }}
  />
);

export default function PrivacyPolicy() {
  return (
    <SiteLayout>
      <Helmet>
        <title>Polityka prywatności</title>
      </Helmet>
      <Section>
        <SectionTitle>
          Polityka Prywatności Serwisu Internetowego i Aplikacji Mobilnej DokDok
        </SectionTitle>
        <SectionContent>
          <Typography variant="h2">
            Administrator:
          </Typography>
          <Typography>
            Administratorem Danych Osobowych jest: DokDok sp. z o.o. z siedzibą we Wrocławiu (50-541), al.
            Armii Krajowej 46ad, wpisana do Krajowego Rejestru Sądowego w Sądzie Rejonowym dla Wrocławia-
            Fabrycznej, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000836794,
            NIP 8943153046, REGON: 385881700, (dalej <strong>„DokDok”</strong>).
          </Typography>
          <Typography variant="h2">
            Obowiązki informacyjne Administratora:
          </Typography>
          <Typography>
            <ol>
              <li>
                Zapisy w niniejszej Polityce Prywatności wyczerpują obowiązki informacyjne DokDok
                wynikające z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z dnia 27
                kwietnia 2016 r. (Dz.Urz. UE L 2016, Nr 119, dalej: <strong>RODO</strong>).
              </li>
              <li>
                Administrator deklaruje, że dołożył wszelkiej staranności, aby obowiązki te wypełnić przy
                zachowaniu kryteriów przejrzystego informowania i przejrzystej komunikacji, o których
                mowa w art. 12 RODO.
              </li>
              <li>
                W przypadku jakichkolwiek niejasności, każda osoba, której dane dotyczą może zwrócić się o
                dodatkowe informacje na adresy podane do kontaktów w sprawach przetwarzania danych
                osobowych.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Dane kontaktowe:
          </Typography>
          <Typography>
            <ol>
              <li>
                Administrator danych osobowych wyznaczył Inspektora Ochrony Danych.
              </li>
              <li>
                Adresem kontaktowym do zgłaszania wszelkich spraw związanych z przetwarzaniem danych
                osobowych Użytkowników serwisu i aplikacji DokDok jest adres e-mail Inspektora Ochrony
                Danych Osobowych: <a href="mailto:iod@dokdok.pl">iod@dokdok.pl</a>
              </li>
              <li>
                Użytkownik serwisu i aplikacji DokDok może zgłosić chęć realizacji swoich uprawnień związanych
                z przetwarzaniem swoich danych osobowych także na adres korespondencyjny: DokDok sp. z
                o.o., al. Armii Krajowej 46ad, 50-541 Wrocław, jak również drogą telefoniczną.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Jakie dane osobowe przetwarzamy:
          </Typography>
          <Typography>
            DokDok zbiera i przetwarza dane osobowe w zakresie niezbędnym do zrealizowania celu, w którym
            zostały zebrane. W zależności od celu oraz podstawy prawnej zebrania i przetwarzania danych
            osobowych DokDok może zbierać i przetwarzać następujące dane:
            <ol>
              <li>
                dane identyfikujące: imię, nazwisko, PESEL, data urodzenia;
              </li>
              <li>
                dane kontaktowe, w tym między innymi: adres, numer telefonu, adres e-mail;
              </li>
              <li>
                dane zbierane i przetwarzane do postawienia diagnozy i przeprowadzenia procesu leczenia,
                w tym również dane szczególnie chronione (dane wrażliwe), w tym dane dotyczące stanu
                zdrowia, z zastrzeżeniem, że dotyczy to wyłącznie osób korzystających ze świadczonych przez
                DokDok usług medycznych. W tej grupie danych mogą się znaleźć również dane dotyczące
                pochodzenia rasowego i etnicznego oraz dane genetyczne, przetwarzane są przez
                Administratora Danych.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Cele i podstawa prawna przetwarzania danych:
          </Typography>
          <Typography>
            <ol>
              <li>
                realizacja umowy dotyczącej świadczenia usług medycznych, udzielanie świadczeń
                medycznych, prowadzenie dokumentacji medycznej – podstawą prawną przetwarzania
                danych jest art. 6 ust. 1 lit. b lub c RODO w związku z art. 3 ust. 1 ustawy o działalności
                leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta oraz
                Rozporządzenie Ministra Zdrowia w sprawie rodzajów, zakresu i wzorów dokumentacji
                medycznej oraz sposobu jej przetwarzania;
              </li>
              <li>
                zapewnienie opieki zdrowotnej oraz zarządzanie systemami i usługami opieki zdrowotnej (np.
                identyfikacji pacjenta w systemie EWUŚ) - podstawą prawną przetwarzania danych jest art.
                6 ust. 1 lit. c oraz art. 9 ust. 2 lit. h RODO w związku z art. 3 ust. 1 ustawy o działalności
                leczniczej oraz art. 24 i 26 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta, § 10 ust. 1
                pkt. 2 Rozporządzenie Ministra Zdrowia w sprawie rodzajów, zakresu i wzorów dokumentacji
                medycznej oraz sposobu jej przetwarzania oraz art. 32 w zw. z art. 3 ust. 1 ustawy o systemie
                informacji o ochronie zdrowia;
              </li>
              <li>
                zapewnienie zabezpieczenia społecznego oraz zarządzanie systemami i usługami
                zabezpieczenia społecznego (wystawianie zaświadczeń lub zwolnień lekarskich) – podstawą
                prawną przetwarzania danych jest art. 9 ust. 2 lit. h RODO w zw. z art. 3 ust. 1 ustawy o
                działalności leczniczej oraz art. 24 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta i art.
                54 o świadczeniach pieniężnych z ubezpieczenia społecznego w razie choroby i
                macierzyństwa.
              </li>
              <li>
                postawienie diagnozy medycznej – podstawą prawną przetwarzania danych jest art. 9 ust. 2
                lit. h) RODO w związku z art. 3ust. 1 ustawy o działalności leczniczej oraz art. 24 ustawy o
                prawach pacjenta i Rzeczniku Praw Pacjenta;
              </li>
              <li>
                realizacja praw pacjenta, w tym prawa do wyznaczenia osoby upoważnionej do
                udostępnienia jej dokumentacji medycznej lub/i informowania jej o stanie zdrowia oraz
                udostępnienia dokumentacji medycznej lub/i informacji - art. 6 ust. 1 lit. c RODO w związku z
                art. 9 ust. h RODO oraz art. 26 ust. 1 ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta
                oraz § 8 ust. 1 Rozporządzenie Ministra Zdrowia w sprawie rodzajów, zakresu i wzorów
                dokumentacji medycznej oraz sposobu jej przetwarzania;
              </li>
              <li>
                kontakt z pacjentem w celu potwierdzenia wizyty, odwołania wizyty, co stanowi prawnie
                uzasadniony interes DokDOk, jakim jest obsługa pacjenta – podstawą prawną przetwarzania
                danych jest art. 6 ust. 1 lit. b i f RODO
              </li>
              <li>
                realizacja umowy o świadczenie usług drogą elektroniczną w postaci prowadzenia konta
                pacjenta w systemie on-line – podstawą prawną przetwarzania danych stanowi art. 6 ust. 1
                lit. a i b RODO
              </li>
              <li>
                ustalenie, dochodzenie roszczeń i obrona przed roszczeniami z tytułu prowadzonej
                działalności gospodarczej, co stanowi prawnie uzasadniony interes DokDok – podstawą
                prawną przetwarzania danych jest art. 6 ust. 1 lit. f RODO;
              </li>
              <li>
                prowadzenie ksiąg rachunkowych i dokumentacji podatkowej - podstawą prawną
                przetwarzania danych jest art. 6 ust. 1 lit. c RODO w zw. z art. 74 ust. 2 ustawy o
                rachunkowości oraz innymi przepisami szczególnymi.
              </li>
              <li>
                w celach marketingowych – dotyczy wyłącznie pacjentów, którzy udzielili zgody na
                przetwarzanie danych w celach marketingowych – podstawą prawną przetwarzania danych
                jest art. 6 ust. 1 lit. a RODO.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Czas przetwarzania danych:
          </Typography>
          <Typography>
            <ol>
              <li>
                  Dane przetwarzane są wyłącznie przez czas niezbędny do osiągnięcia celów wymienionych w
                  punkcie poprzedzającym.
              </li>
              <li>
                Dane przetwarzane zgodnie z punktami od 1 do 6 powyżej przetwarzane są przez okres
                przechowywania dokumentacji medycznej wynikający z przepisów prawa. Obecnie jest to 20
                lat od dnia dokonania ostatniego wpisu w dokumentacji medycznej.
              </li>
              <li>
                Dane przetwarzane na podstawie punktu 7 powyżej są przetwarzane przez okres
                prowadzenia konta.
              </li>
              <li>
                Dane, o których mowa w punkcie 8 powyżej są przetwarzane przez okres przedawnienia
                roszczeń określony w przepisach kodeksu cywilnego. Obecnie jest to 6 lat od daty wykonania
                umowy.
              </li>
              <li>
                Dane, o których mowa w punkcie 9 powyżej są przetwarzane przez okres przetwarzania
                dokumentacji księgowej wynikający z przepisów prawa, tj. 5 lat od końca roku
                kalendarzowego, w którym powstał obowiązek podatkowy.
              </li>
              <li>
                Dane przetwarzane w celach marketingowych na podstawie Twojej zgody są przetwarzane
                do czasu jej wycofania.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Skąd mamy Twoje dane:
          </Typography>
          <Typography>
            Źródłem przetwarzanych przez DokDok są osoby, których dane dotyczą, czyli Ty sam.
          </Typography>
          <Typography variant="h2">
            Komu możemy przekazać Twoje dane:
          </Typography>
          <Typography>
            <ol>
              <li>
                Odbiorcami danych mogą być następujące kategorie podmiotów:
                <ol type="a">
                  <li>
                    podmioty medyczne świadczące usługi medyczne dostępne w Serwisie i Aplikacji
                    DokDok. Lista naszych Partnerów, których usługi dostępne są w naszym Serwisie i
                    Aplikacji Mobilnej znajduje na stronie <a href="https://dokdok.pl/lista-podmiotow-rodo">https://dokdok.pl/lista-podmiotow-rodo</a>.
                  </li>
                  <li>
                    podmioty świadczące na rzecz DokDok usługi wsparcia informatycznego, w
                    szczególności spółka Code White sp. z o.o. z siedzibą we Wrocławiu;
                  </li>
                  <li>
                    dostawcy usług zaopatrujących DokDok w rozwiązania techniczne oraz organizacyjne,
                    umożliwiające udzielanie świadczeń zdrowotnych oraz zarządzanie naszą organizacją
                    (w szczególności dostawcom usług teleinformatycznych, dostawcom sprzętu
                    diagnostycznego, firmom kurierskim i pocztowym);
                  </li>
                  <li>
                    dostawcy usług wspierających DokDok w obszarze marketingowym (np. firmy
                    realizujące wysyłkę sms, e-mail);
                  </li>
                  <li>
                    dostawcy usług prawnych i doradczych oraz wspierających DokDok w dochodzeniu
                    należnych roszczeń (kancelariom prawnym, biurom informacji gospodarczej, firmom
                    windykacyjnym);
                  </li>
                  <li>
                    osobom upoważnionym przez Ciebie w ramach realizacji Twoich praw pacjenta
                  </li>
                  <li>
                    podmiotom zlecającym realizację usług zdrowotnych, w szczególności Narodowemu
                    Funduszowi Zdrowia
                  </li>
                  <li>
                    audytorom zewnętrznym.
                  </li>
                </ol>
              </li>
              <li>
                Udostępnienie Twoich danych ww. podmiotom odbywa się w zgodzie z obowiązującymi
                przepisami oraz przy zachowaniu pełnych zasad związanych z ich bezpieczeństwem.
              </li>
              <li>
                Twoje dane osobowe nie są przekazywane do państwa trzeciego w rozumieniu przepisów RODO.
              </li>
            </ol>
          </Typography>
          <Typography variant="h2">
            Automatyczne podejmowanie decyzji i profilowanie
          </Typography>
          <Typography>
            Twoje dane mogą podlegać profilowaniu w celach komunikacji marketingowej, w tym tworzeniu
            statystyk dla celów marketingowych, a także działań remarketingowych. Wobec Twoich danych mogą
            być podejmowane zautomatyzowane decyzje w zakresie kierowanej komunikacji marketingowej.
          </Typography>
          <Typography variant="h2">
            Twoje prawa:
          </Typography>
          <Typography>
            Przysługuje Ci prawo do:
            <ol>
              <li>
                dostępu do swoich danych osobowych, sprostowania, usunięcia, ograniczenia
                przetwarzania, prawo do wniesienia sprzeciwu, oraz prawo do przenoszenia danych w
                przypadkach określonych w przepisach RODO,
              </li>
              <li>
                cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
                którego dokonano na podstawie zgody przed jej cofnięciem (dotyczy w szczególności
                przetwarzania danych w celach marketingowych),
              </li>
              <li>
                wniesienia w każdym momencie sprzeciwu na przetwarzanie danych osobowych - z przyczyn
                związanych ze szczególną sytuacją osoby, której te dane dotyczą, a także prawo wniesienia
                sprzeciwu na przetwarzanie danych w celach marketingu bezpośredniego,
              </li>
              <li>
                wniesienia skargi do organu nadzorczego jeśli osoba, której dane dotyczą uzna, że
                przetwarzanie danych przez Administratora narusza przepisy RODO lub przepisy krajowe.
              </li>
            </ol>
            Aby skorzystać z ww. praw, powinieneś skontaktować się, wykorzystując podane dane kontaktowe, z
            Inspektorem Ochrony Danych Osobowych i poinformować go, z którego prawa i w jakim zakresie
            chcesz skorzystać.
          </Typography>
          <Typography variant="h2">
            Organ nadzorczy:
          </Typography>
          <Typography>
            Organem Nadzorczym jest Prezes Urzędu Ochrony Danych Osobowych, adres: ul. Stawki 2, 00-193 Warszawa.
          </Typography>
          <Typography variant="h2">
            Dobrowolność podania danych:
          </Typography>
          <Typography>
            Podanie przez Ciebie danych osobowych jest dobrowolne, jednak stanowi warunek ustawowy w
            zakresie prowadzenia przez DokDok dokumentacji, w tym dokumentacji medycznej w sposób
            określony przepisami prawa. Podanie danych jest także warunkiem zawarcia umowy o świadczenie
            usługi prowadzenia konta. Odmowa podania danych skutkować będzie brakiem możliwości
            założenia i prowadzenia konta w systemie on-line, niemożnością rezerwacji wizyty, rejestracji albo
            odmową udzielenia świadczenia zdrowotnego.
          </Typography>
          <Typography variant="h2">
            Bezpieczeństwo danych:
          </Typography>
          <Typography>
            DokDok oświadcza, że stosuje odpowiednie środki techniczne i organizacyjne by Twoje dane
            osobowe były bezpieczne, w tym zabezpiecza dane osobowe przed nieuprawnionym dostępem, ich
            utratą lub zniszczeniem. W celu zapewnienia bezpieczeństwa danych osobowych w DokDok zostały
            wdrożone odpowiednie zabezpieczenia personalne, organizacyjne, techniczne (informatyczne) i
            fizyczne.
          </Typography>
          <br />
        </SectionContent>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
